<script setup>
import { computed } from 'vue';
import Widget from './Widget.vue';

const isLocked = formychat_vars.is_premium == '1' ? false : true;

const DEFAULT = formychat_vars.data.default_config || {};

const postId = formychat_vars.current.post_id || 0;
const postType = formychat_vars.current.post_type || 'page';
// const isHome = formychat_vars.current.is_home || formychat_vars.current.is_front_page || false;

const widgets = computed(() => {
    return formychat_vars.data.widgets?.filter(widget => {
        if (isLocked) return true;

        // No restriction for home and non-page post types.
        if (postType !== 'page') return true

        const exclude_pages = widget.config.target.exclude_pages || [];
        const exclude_all_pages = widget.config.target.exclude_all_pages || false;
        const exclude_all_pages_except = widget.config.target.exclude_all_pages_except || [];

        if (exclude_all_pages) return exclude_all_pages_except.some(page => page == postId);

        // If in exclude_pages, then hide.
        return !exclude_pages.some(page => page == postId);
    }).map(widget => {

        if (isLocked) {
            // Restrict premium config.

            const config = { ...widget.config };

            config.whatsapp.web_version = DEFAULT.whatsapp.web_version;
            config.whatsapp.message_template = DEFAULT.whatsapp.message_template;

            config.icon.image_url = DEFAULT.icon.image_url;
            config.icon.size = widget.config.icon.size === 'custom' ? DEFAULT.icon.size : widget.config.icon.size;
            config.icon.position = widget.config.icon.position === 'custom' ? DEFAULT.icon.position : widget.config.icon.position;

            config.cta.color = DEFAULT.cta.color;
            config.cta.background_color = DEFAULT.cta.background_color;

            config.form.size = widget.config.form.size === 'custom' ? DEFAULT.form.size : widget.config.form.size;
            config.form.show_country_code_field = DEFAULT.form.show_country_code_field;
            config.form.text_color = DEFAULT.form.text_color;
            config.form.background_color = DEFAULT.form.background_color;

            config.greetings.style = DEFAULT.greetings.style;
            config.greetings.on_click = DEFAULT.greetings.on_click;

            config.greetings.templates.simple.heading_size = widget.config.greetings.templates.simple.heading_size === 'custom' ? DEFAULT.greetings.templates.simple.heading_size : widget.config.greetings.templates.simple.heading_size;
            config.greetings.templates.simple.message_size = widget.config.greetings.templates.simple.message_size === 'custom' ? DEFAULT.greetings.templates.simple.message_size : widget.config.greetings.templates.simple.message_size;
            config.greetings.templates.simple.message_color = widget.config.greetings.templates.simple.message_color === 'custom' ? DEFAULT.greetings.templates.simple.message_color : widget.config.greetings.templates.simple.message_color;
            config.greetings.templates.simple.background_color = widget.config.greetings.templates.simple.background_color === 'custom' ? DEFAULT.greetings.templates.simple.background_color : widget.config.greetings.templates.simple.background_color;

            config.greetings.templates.wave.icon_url = DEFAULT.greetings.templates.wave.icon_url;
            config.greetings.templates.wave.icon_position = DEFAULT.greetings.templates.wave.icon_position;
            config.greetings.templates.wave.heading_size = widget.config.greetings.templates.wave.heading_size === 'custom' ? DEFAULT.greetings.templates.wave.heading_size : widget.config.greetings.templates.wave.heading_size;
            config.greetings.templates.wave.message_size = widget.config.greetings.templates.wave.message_size === 'custom' ? DEFAULT.greetings.templates.wave.message_size : widget.config.greetings.templates.wave.message_size;
            config.greetings.templates.wave.message_color = DEFAULT.greetings.templates.wave.message_color;
            config.greetings.templates.wave.background_color = DEFAULT.greetings.templates.wave.background_color;
            config.greetings.templates.wave.cta_icon_url = DEFAULT.greetings.templates.wave.cta_icon_url;
            config.greetings.templates.wave.cta_text_color = DEFAULT.greetings.templates.wave.cta_text_color;
            config.greetings.templates.wave.cta_background_color = DEFAULT.greetings.templates.wave.cta_background_color;
            config.greetings.templates.wave.cta_heading_color = DEFAULT.greetings.templates.wave.cta_heading_color;
            config.greetings.templates.wave.cta_message_color = DEFAULT.greetings.templates.wave.cta_message_color;
            config.greetings.templates.wave.cta_heading_size = widget.config.greetings.templates.wave.cta_heading_size === 'custom' ? DEFAULT.greetings.templates.wave.cta_heading_size : widget.config.greetings.templates.wave.cta_heading_size;
            config.greetings.templates.wave.cta_message_size = widget.config.greetings.templates.wave.cta_message_size === 'custom' ? DEFAULT.greetings.templates.wave.cta_message_size : widget.config.greetings.templates.wave.cta_message_size;

            widget.config = config;

        }

        return widget;
    });
});

</script>
<template>
    <div class="formychat-widgets">
        <Widget v-for="widget in widgets" :key="widget.id" :widget="widget" />
    </div>
</template>