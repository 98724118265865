import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';
import axios from 'axios';

(function ($) {
    $(document).ready(function () {

        $(document).on('gform_confirmation_loaded', async function (event, formId) {
            const rest = await axios.get(formychat_vars.ajax_url + '?action=formychat_get_gf_entry&id=' + formId + '&nonce=' + formychat_vars.nonce);
            const settings = rest.data.data.formychat || {};

            // Closest .formychat-widget-custom-form of the e.target
            // const form = $(`form`);
            const widget = $('[data-whatsapp]');

            // If form is found, then use that.
            if ((widget && widget.length === 0) && !settings.status) return;

            // Notify parent.
            window.parent && window.parent.postMessage({ formychat_submitted: true }, '*');

            // Save inputs.
            let data = {
                form: 'gravity_forms',
                form_id: formId,
                field: settings.values,
                meta: getMeta.value,
            }

            let message = getMessage(settings.message, settings.values);

            const whatsapp = widget ? widget.data('whatsapp') : null;
            console.log('message', message);

            if (whatsapp) {

                const newTab = widget.data('new-tab');
                const web = widget.data('web');
                save(data, whatsapp, message, isDesktop() && web == 1, newTab == 1);
                openWhatsapp(whatsapp, message, isDesktop() && web == 1, newTab == 1);
                return;
            }

            save(data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
            openWhatsapp(settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
        });


        window.gform_formychat = function (form, entries = {}, settings) {
            // Object to store label-value pairs
            let values = {};
        
            // Loop through fields in the form
            form.fields.forEach(field => {
                let label = field.label; // Get the label of the field
                let value;
        
                // Check if the field has multiple inputs (e.g., name fields with prefix, first, last, etc.)
                if (field.inputs && Array.isArray(field.inputs)) {
                    // Concatenate values for multi-input fields
                    value = field.inputs.map(input => {
                        const inputValue = entries[input.id] || ''; // Get the entry value for each sub-field
                        return inputValue.trim(); // Trim spaces to avoid unnecessary gaps
                    }).filter(v => v).join(' '); // Join non-empty values with a space
                } else {
                    // For single-input fields, get the value directly
                    value = entries[field.id] || '';
                }
        
                // Add the label-value pair to the values object
                if (label && value) {
                    values[label] = value;
                }
            });
        
            // Save inputs.
            let data = {
                form: 'gravity_forms',
                form_id: form.id,
                field: values,
                meta: getMeta.value,
            }

            let message = getMessage(settings.message, values);

            // console.log('message', message, data);
            save(data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
            openWhatsapp(settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
        };
        

        // Trigger new custom event formychat_loaded
        const event = new CustomEvent('formychat_loaded', {
            detail: { }
        });

        document.dispatchEvent(event);

    });

})(jQuery)