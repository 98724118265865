import { createApp } from "vue";
import './integration/cf7'
import './integration/gravityforms'
import './integration/wpforms'
import './../admin/utility/IPAddress'

import App from "./App.vue";

(() => {
    const app = createApp(App);
    app.mount("#formychat-widgets");
})();