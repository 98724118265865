import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';

(($) => {
    // Each wpforms.
    async function catchWPForms(event, json) {
        const form = event.target;
        const formId = form.dataset.formid;
        const settings = json.data.formychat || {}

        // Closest .formychat-widget-custom-form of the e.target
        const widget = form.closest('[data-whatsapp]') || null;
        
        // console.log('wpforms', widget, settings);
        
        // If form is found, then use that.
        if ( !widget && !settings.status ) return;

        // Save inputs.
        let data = {
            form: 'wpforms',
            form_id: formId,
            field: Object.fromEntries(settings.fields.map(input => [input.name, input.value])),
            meta: getMeta.value,
        }

        let message = getMessage(settings.message, Object.fromEntries(settings.fields.map(input => [input.name, input.value])));
       

        // Notify parent.
        window.parent.postMessage({ formychat_submitted: true }, '*');

        const whatsapp = widget ? widget.dataset.whatsapp : null;
        if ( whatsapp ) {
            const newTab = widget.getAttribute('data-new-tab');
            const web = widget.getAttribute('data-web');
            save(data, whatsapp, message, isDesktop() && web == 1, newTab == 1);
            openWhatsapp( whatsapp, message, isDesktop() && web == 1, newTab == 1 );
            return;
        }
        
        save(data, settings.whatsapp_number , message, isDesktop(), 1 == settings.new_tab);
        openWhatsapp ( settings.whatsapp_number , message, isDesktop(), 1 == settings.new_tab );
    }

    $(document).on('wpformsAjaxSubmitSuccess', catchWPForms);
})(jQuery)