import { save, openWhatsapp, getMeta, isDesktop, getMessage } from './../store.js';

(function ($) {
    $(document).ready(function () {
        /** Catch CF7 Form */
        const catchForm = async (e) => {

            // Bail if not successful
            if (e.detail.apiResponse.status !== 'mail_sent') return;

            const settings = e.detail.apiResponse.formychat || {}

            // Collect lead data
            const inputs = Object.fromEntries(e.detail.inputs.map(input => [input.name, input.value])) || {};
            
            // Closest .formychat-widget-custom-form of the e.target
            const form = e.target;
            const widget = form.closest('[data-whatsapp]');
            
            // If form is found, then use that.
            if ((widget && widget.length == 0) && 'on' !== settings.status) return;

            // Notify parent.
            window.parent.postMessage({ formychat_submitted: true }, '*');

            // Save inputs.
            let data = {
                form: 'cf7',
                form_id: e.detail.contactFormId,
                field: inputs,
                meta: getMeta.value,
            }

            // Open whatsapp.
            // let message = settings.message || '';
            let message = getMessage(settings.message, inputs, /\[(.*?)\]/);

            const whatsapp = widget ? widget.dataset.whatsapp : null;

            if (whatsapp) {
                const newTab = widget.dataset.newTab || 0;
                const web = widget.dataset.web || 0;
                save(data, whatsapp, message, isDesktop() && web == 1, newTab == 1);
                openWhatsapp(whatsapp, message, isDesktop() && web == 1, newTab == 1);
                return;
            }

            // Async save data
            save(data, settings.whatsapp_number, message, isDesktop(), 'on' == settings.new_tab);
            openWhatsapp(settings.whatsapp_number, message, isDesktop(), 'on' == settings.new_tab);
        }

        document.addEventListener('wpcf7submit', catchForm);

    });

})(jQuery)