/* The code you provided is an Immediately Invoked Function Expression (IIFE) in JavaScript. Here's a
breakdown of what it does: */
(() => {
    
    /**
     * The `loadClientInformation` function fetches and caches IP data, emitting a custom event with
     * the data when loaded.
     * @returns The `loadClientInformation` function returns the IP data fetched from the API or
     * retrieved from the cache. If the fetch request is successful and valid data is received, it
     * returns the data object. If there is an error during the process, it sets `window.formychat_ip`
     * to `null` and returns `null`.
     */
    const loadClientInformation = async () => {
        const CACHE_KEY = 'formychat_ip_data';
        const DAY = 24 * 60 * 60 * 1000;

        const emitLoaded = (data) => {
            delete data.success;
            window.formychat_ip = data;
            window.dispatchEvent(new CustomEvent('formychat_ip_loaded', {
                detail: data /* The comment `// The API response is directly passed as detail` is
                explaining that the API response data received from the fetch request
                is directly passed as the `detail` property of the CustomEvent that is
                dispatched when the IP data is loaded. This allows other parts of the
                code to access the specific IP data easily by accessing the `detail`
                property of the event object when handling the `formychat_ip_loaded`
                event. */
            }));
        };

        try {
            // Check cache
            const cached = localStorage.getItem(CACHE_KEY);
            if (cached) {
                const { timestamp, data } = JSON.parse(cached);
                if (Date.now() - timestamp < DAY) {
                    emitLoaded(data);
                    return data;
                }
            }

            // Fetch new
            const res = await fetch("https://ipwhois.app/json/");
            if (!res.ok) throw new Error('Failed to fetch IP data');

            const data = await res.json();
            if (!data || typeof data !== 'object') throw new Error('Invalid IP data');

            localStorage.setItem(CACHE_KEY, JSON.stringify({
                timestamp: Date.now(),
                data
            }));

            emitLoaded(data);
            return data;

        } catch (error) {
            window.formychat_ip = null;
            return null;
        }
    }

    document.addEventListener('DOMContentLoaded', loadClientInformation);

   /* The code snippet `window.addEventListener('formychat_ip_loaded', (e) => {
       window.formychat_country_code = e.detail?.country_phone?.replace('+', '') || '44'
   });` is adding an event listener to the `window` object. Specifically, it is listening for a
   custom event named `'formychat_ip_loaded'`. */
    window.addEventListener('formychat_ip_loaded', (e) => {
        window.formychat_country_code = e.detail?.country_phone?.replace('+', '') || '44'
    });
})()